/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function isolateFilters(data: any) {
  const filtersData = []

  for (const [key, value] of Object.entries(data.metafields)) {
    let mappedValues: any = []
    if (value !== null && Array.isArray(value)) {
      mappedValues = value.map(entry => {
        const option = {
          name: entry,
          value: entry,
        }
        return option
      })
    }
    let formatedName = key.replace(/Filter/u, '')
    if (data.templateKey === 'antique-rugs') {
      formatedName = key.replace(/Filter/u, '') === 'size' ? 'filterSize' : 'antiquesRugsColor'
    }
    if (data.templateKey === 'rugs-stacked' || data.templateKey === 'rugs') {
      switch (formatedName) {
        case 'size':
          formatedName = 'rugSize'
          break
        case 'color':
          formatedName = 'rugColors'
          break
        case 'pileHeight':
          formatedName = 'rugPileHeight'
          break
        case 'style':
          formatedName = 'rugStyle'
          break
        case 'design':
          formatedName = 'rugDesign'
          break
        case 'shape':
          formatedName = 'shape'
          break
        case 'fiber':
          formatedName = 'rugFiber'
          break
        default:
          formatedName = key.replace(/Filter/u, '')
      }
    }
    const filter = {
      name: formatedName,
      label: key.replace(/Filter/u, '') === 'pileHeight' ? 'pile' : key.replace(/Filter/u, ''),
      items: mappedValues,
    }
    if (mappedValues.length > 0) {
      filtersData.push(filter)
    }
  }

  return filtersData
}
